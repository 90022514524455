import classNames from 'classnames';
import { Accordion, Progress } from '@mantine/core';
import { AppBadge, AppButton, AppCreatorLabel } from '../../../../components';
import { Group, Avatar, Text } from '@mantine/core';
import {
  CollectionType,
  FundingCollectionDto,
  Stage,
  StageName,
  useAddToWaitlistMutation,
} from '../../../../modules/collection';
import { formatCurrency, getPercentage, resizeImage } from '../../../../utils';
import { IconBellRinging, IconChevronDown } from '@tabler/icons-react';
import { useAppModals } from '../../../../modals';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../../../modules/user';
import { AppRoute } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import { showSuccess } from '../../../../notifications';
import styles from './FundingCollectionMinter.module.scss';
import { selectUser, WalletType } from '../../../../modules/auth';
import { useState } from 'react';

type Props = {
  className: string;
  collection: FundingCollectionDto;
};

const stageMap = {
  [StageName.PRE_PRODUCTION]: 'Pre-production',
  [StageName.PRODUCTION]: 'Production',
  [StageName.POST_PRODUCTION]: 'Post-production',
};

export function FundingCollectionMinter({ className, collection }: Props) {
  const profile = useSelector(selectProfile);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [addToWaitlistRequest, { isLoading }] = useAddToWaitlistMutation();

  const {
    id,
    user: creator,
    symbol,
    name,
    goal,
    waitlistCount,
    waitlistSize,
    options,
    ownedBy,
    isWaitlist,
    waitlistDiscount,
    hasDiscount,
    activeStage,
  } = collection;
  const { nftImage, tokenCount } = options[0];
  const [waitlistDisabled, setWaitlistDisabled] = useState(hasDiscount);
  const modals = useAppModals();
  const updateWaitlistCount =
    id === '64cc0f3b177db0ae74646b94' ? 237 + waitlistCount : waitlistCount;

  function toOptions() {
    document.getElementById('options')?.scrollIntoView();
  }

  function handleWaitlistClick() {
    if (profile && user) {
      const email = profile.email || profile.emailForNotifications;

      if (email) {
        addToWaitlist(email);
      } else {
        modals.openGetNotifiedModal({
          onSuccess: addToWaitlist,
        });
      }
    } else {
      connect();
    }
  }

  function connect() {
    navigate(AppRoute.Connect, {
      state: { redirectRoute: `${AppRoute.Collection}/${CollectionType.Funding}/${symbol}` },
    });
  }

  function addToWaitlist(email: string) {
    const modalId = modals.openSpinner();
    addToWaitlistRequest({ email, symbol })
      .unwrap()
      .then(() => {
        showSuccess(`You're all set. Check your email`, `We'll notify you before it drops`);
        setWaitlistDisabled(true);
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  const collected = options.reduce((acc, curr) => {
    return curr.price * curr.tokenCount + acc;
  }, 0);

  const stages = collection.activeStage ? [collection.activeStage] : [];

  function AccordionLabel({ stage, isWaitlist }: { stage: Stage; isWaitlist: boolean }) {
    const status =
      stage.name === collection.activeStage?.name
        ? isWaitlist
          ? 'waitlist'
          : 'live now'
        : 'ended';

    return (
      <div className={styles.accordionLabel}>
        <div className={styles.stage}>{stageMap[stage.name]}</div>
        <AppBadge type={status} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.collectionMinter, className)}>
      <div className={styles.header}>
        <AppBadge type={CollectionType.Funding} />
        <div className={styles.titleBlock}>
          <div className={styles.title}>{name}</div>
          <AppCreatorLabel {...creator} isSmall isIconDisplayed={false} />
        </div>
      </div>
      <div className={styles.content}>
        <Accordion
          styles={{
            content: { padding: 0 },
            control: { padding: 0 },
            chevron: { marginLeft: 0 },
            label: { padding: 0 },
          }}
          chevronPosition='right'
          defaultValue={collection.activeStage?.id}
          variant='filled'
        >
          {collection.stages.map((item) => (
            <Accordion.Item style={{ backgroundColor: '#1d1e20' }} value={item.id} key={item.name}>
              <Accordion.Control disabled={item.name !== collection.activeStage?.name}>
                <AccordionLabel stage={item} isWaitlist={isWaitlist} />
              </Accordion.Control>
              <Accordion.Panel>
                <div>
                  {isWaitlist ? (
                    <div className={styles.valueBlock}>
                      <Progress
                        radius='xl'
                        size='md'
                        value={getPercentage(updateWaitlistCount, waitlistSize)}
                        styles={{
                          root: {
                            background: '#292A2E',
                          },
                        }}
                      />

                      <div
                        className={styles.goal}
                      >{`${updateWaitlistCount} of ${waitlistSize} people in waitlist`}</div>
                    </div>
                  ) : (
                    <div className={styles.valueBlock}>
                      <div className={styles.collected}>{formatCurrency(collected)}</div>

                      <Progress
                        radius='xl'
                        size='md'
                        value={getPercentage(collected, goal)}
                        styles={{
                          root: {
                            background: '#292A2E',
                          },
                        }}
                      />

                      <div className={styles.goal}>{`Funded of ${formatCurrency(goal)} goal`}</div>
                    </div>
                  )}

                  <div className={styles.options}>
                    <div className={styles.stat}>
                      <div className={styles.statName}>Options</div>
                      <div className={styles.statValue}>
                        <div>{options.length}</div>
                        <img src={resizeImage(nftImage, 28, 28)} alt='Nft Image' />
                      </div>
                    </div>

                    <div className={styles.divider} />

                    {isWaitlist ? (
                      <div className={styles.stat}>
                        <div className={styles.statName}>Waitlisted</div>
                        <div className={styles.statValue}>{updateWaitlistCount}</div>
                      </div>
                    ) : (
                      <div className={styles.stat}>
                        <div className={styles.statName}>Owned By</div>
                        <div className={styles.statValue}>{ownedBy}</div>
                      </div>
                    )}

                    <div className={styles.divider} />

                    <div className={styles.stat}>
                      <div className={styles.statName}>Rights</div>
                      <div className={styles.statValue}>{activeStage?.percentage}%</div>
                    </div>
                  </div>

                  {isWaitlist && (
                    <div className={styles.waitlistLabel}>
                      {`Be one of the exclusive ${waitlistSize} people who will receive a ${waitlistDiscount}% discount.`}
                    </div>
                  )}
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>

        <div className={styles.buttons}>
          {isWaitlist && (
            <AppButton
              disabled={waitlistDisabled}
              onClick={handleWaitlistClick}
              className={styles.button}
              radius='round'
              variant='white'
            >
              {waitlistDisabled ? (
                <span>You have subscribed</span>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Join Waitlist <IconBellRinging style={{ height: 20, width: 20, marginLeft: 8 }} />
                </div>
              )}
            </AppButton>
          )}
          <AppButton
            onClick={toOptions}
            className={styles.button}
            radius='round'
            variant={isWaitlist ? 'dark2' : 'white'}
          >
            {isWaitlist ? 'See options' : 'Collect'} <IconChevronDown />
          </AppButton>
        </div>
      </div>
    </div>
  );
}
