import styles from './Footer.module.scss';
import { Brand } from '../app-brand/Brand';
import { Loading } from '../../pages';
import background from '../../images/background.png';
import {
  IconFacebook,
  IconImdb,
  IconInstagram,
  IconSpotify,
  IconTwitter,
  IconWebsite,
} from '../../icons';

export function Footer() {
  return (
    <div className={styles.footer}>
      <img className={styles.image} src={background} />
      <div className={styles.upperContainer}>
        <div className={styles.brandContainer}>
          <div className={styles.brand}>
            <Brand className={styles.logo} color='gradient' />
            indieline
          </div>

          <div className={styles.address}>
            8 The Green, Ste R, Dover, Delaware, US
            <br /> support@indieline.io
            <br /> +1 (888) 640-1717
          </div>
        </div>
        <div className={styles.links}>
          <div className={styles.section}>
            <div className={styles.label}>Legals</div>
            <div className={styles.link}>Terms & Conditions</div>
            <div className={styles.link}>Privacy Policy</div>
            <div className={styles.link}>FAQ</div>
          </div>
          <div className={styles.section}>
            <div className={styles.label}>Resources</div>
            <div className={styles.link}>Funding Collection</div>
            <div className={styles.link}>Fan Collection</div>
            <div className={styles.link}>Marketplace</div>
            <div className={styles.link}>Blog</div>
          </div>
          <div className={styles.section}>
            <div className={styles.label}>Support</div>
            <div className={styles.link}>Become a Creator</div>
            <div className={styles.link}>Book a Call</div>
            <div className={styles.link}>Knowledge Base</div>
          </div>
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <div className={styles.copyright}>© 2023 Indieline, Inc. All rights reserved.</div>
        <div className={styles.socials}>
          {[
            { Icon: IconWebsite, link: '', id: 1 },
            { Icon: IconImdb, link: '', id: 2 },
            { Icon: IconTwitter, link: '', id: 3 },
            { Icon: IconInstagram, link: '', id: 4 },
            { Icon: IconFacebook, link: '', id: 5 },
            { Icon: IconSpotify, link: '', id: 6 },
          ].map(({ Icon, link, id }) => (
            <Icon key={id} className={styles.social} />
          ))}
        </div>
      </div>
    </div>
  );
}
